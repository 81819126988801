import React from "react";
import ReactDOM from "react-dom/client";
import "bootstrap/dist/css/bootstrap.css";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import store, { persistor } from "./store";
import { AuthProvider } from "react-oauth2-code-pkce";
import "chart.js/auto";
//
import { PersistGate } from "redux-persist/integration/react";
//
const authConfig = {
  // for localhost
  // clientId: "ab6c4820-53f5-11ee-8c99-0242ac120002",
  // for live
  clientId: "ee2e56aa-5d35-11ee-8c99-0242ac120002",

  authorizationEndpoint:
    "https://trial.oauth.service.oneintrinity.com/oauth2/authorize",
  tokenEndpoint: "https://trial.oauth.service.oneintrinity.com/oauth2/token",

  // for localhost
  // redirectUri: "http://localhost:3000/token",
  // for live
  redirectUri: "https://trial.oneintrinity.com/token",

  logoutEndpoint: "https://trial.oauth.service.oneintrinity.com/logout",

  // for localhost
  // logoutRedirect: "http://localhost:3000/",
  // for live
  logoutRedirect: "https://trial.oneintrinity.com/",
  autoLogin: true,

  // scope: "someScope openid",
  onRefreshTokenExpire: (event) =>
    window.confirm(
      "Session expired. Refresh page to continue using the site?"
    ) && event.login(),
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <AuthProvider authConfig={authConfig}>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  </AuthProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
