import React from "react";
import { Col, Form, Row } from "react-bootstrap";

export const DashboardUserPermission = ({
  userPermission,
  setUserPermission,
}) => {
  return (
    <div>
      <div>
        <p className="fw-bold text-primary">Dashboard Access Permission</p>
        <Row>
          <Col sm={4}>
            <Form.Check
              onChange={(e) => {
                if (e.target?.checked) {
                  setUserPermission((prevValue) => [
                    ...prevValue,
                    "READ_DASHBOARD",
                  ]);
                } else {
                  setUserPermission((prevValue) =>
                    prevValue?.filter(
                      (permission) => permission !== "READ_DASHBOARD"
                    )
                  );
                }
              }}
              checked={userPermission?.includes("READ_DASHBOARD")}
              type="switch"
              label="Read Dashboard"
            />
          </Col>

          <Col sm={4}>
            <Form.Check
              onChange={(e) => {
                if (e.target?.checked) {
                  setUserPermission((prevValue) => [
                    ...prevValue,
                    "READ_BIRTHDAY",
                  ]);
                } else {
                  setUserPermission((prevValue) =>
                    prevValue?.filter(
                      (permission) => permission !== "READ_BIRTHDAY"
                    )
                  );
                }
              }}
              checked={userPermission?.includes("READ_BIRTHDAY")}
              type="switch"
              label="Read Birthday"
            />
          </Col>

          <Col sm={4}>
            <Form.Check
              onChange={(e) => {
                if (e.target?.checked) {
                  setUserPermission((prevValue) => [
                    ...prevValue,
                    "READ_ANNIVERSARY",
                  ]);
                } else {
                  setUserPermission((prevValue) =>
                    prevValue?.filter(
                      (permission) => permission !== "READ_ANNIVERSARY"
                    )
                  );
                }
              }}
              checked={userPermission?.includes("READ_ANNIVERSARY")}
              type="switch"
              label="Read Anniversary"
            />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export const FamiliesUserPermission = ({
  userPermission,
  setUserPermission,
}) => {
  return (
    <div>
      <div>
        <p className="fw-bold text-primary">Family Permission</p>
        <Row>
          <Col sm={3}>
            <Form.Check
              onChange={(e) => {
                if (e.target?.checked) {
                  setUserPermission((prevValue) => [
                    ...prevValue,
                    "READ_FAMILY",
                  ]);
                } else {
                  setUserPermission((prevValue) =>
                    prevValue?.filter(
                      (permission) => permission !== "READ_FAMILY"
                    )
                  );
                }
              }}
              checked={userPermission?.includes("READ_FAMILY")}
              type="switch"
              label="Read Family"
            />
          </Col>

          <Col sm={3}>
            <Form.Check
              onChange={(e) => {
                if (e.target?.checked) {
                  setUserPermission((prevValue) => [
                    ...prevValue,
                    "WRITE_FAMILY",
                  ]);
                } else {
                  setUserPermission((prevValue) =>
                    prevValue?.filter(
                      (permission) => permission !== "WRITE_FAMILY"
                    )
                  );
                }
              }}
              checked={userPermission?.includes("WRITE_FAMILY")}
              type="switch"
              label="Write Family"
            />
          </Col>

          <Col sm={3}>
            <Form.Check
              onChange={(e) => {
                if (e.target?.checked) {
                  setUserPermission((prevValue) => [
                    ...prevValue,
                    "UPDATE_FAMILY",
                  ]);
                } else {
                  setUserPermission((prevValue) =>
                    prevValue?.filter(
                      (permission) => permission !== "UPDATE_FAMILY"
                    )
                  );
                }
              }}
              checked={userPermission?.includes("UPDATE_FAMILY")}
              type="switch"
              label="Update Family"
            />
          </Col>

          <Col sm={3}>
            <Form.Check
              onChange={(e) => {
                if (e.target?.checked) {
                  setUserPermission((prevValue) => [
                    ...prevValue,
                    "UPDATE_FAMILY_HEAD",
                  ]);
                } else {
                  setUserPermission((prevValue) =>
                    prevValue?.filter(
                      (permission) => permission !== "UPDATE_FAMILY_HEAD"
                    )
                  );
                }
              }}
              checked={userPermission?.includes("UPDATE_FAMILY_HEAD")}
              type="switch"
              label="Update Family Head"
            />
          </Col>
        </Row>
      </div>
      <hr />

      <div>
        <p className="fw-bold text-primary">Families Permission</p>
        <Row>
          <Col sm={4}>
            <Form.Check
              onChange={(e) => {
                if (e.target?.checked) {
                  setUserPermission((prevValue) => [
                    ...prevValue,
                    "READ_FAMILY_HEADS",
                  ]);
                } else {
                  setUserPermission((prevValue) =>
                    prevValue?.filter(
                      (permission) => permission !== "READ_FAMILY_HEADS"
                    )
                  );
                }
              }}
              checked={userPermission?.includes("READ_FAMILY_HEADS")}
              type="switch"
              label="Read Families List"
            />
          </Col>

          <Col sm={4}>
            <Form.Check
              onChange={(e) => {
                if (e.target?.checked) {
                  setUserPermission((prevValue) => [
                    ...prevValue,
                    "READ_MEMBER_SEARCH",
                  ]);
                } else {
                  setUserPermission((prevValue) =>
                    prevValue?.filter(
                      (permission) => permission !== "READ_MEMBER_SEARCH"
                    )
                  );
                }
              }}
              checked={userPermission?.includes("READ_MEMBER_SEARCH")}
              type="switch"
              label="Global Member Search List"
            />
          </Col>

          <Col sm={4}>
            <Form.Check
              onChange={(e) => {
                if (e.target?.checked) {
                  setUserPermission((prevValue) => [
                    ...prevValue,
                    "READ_FAMILY_MEMBERS",
                  ]);
                } else {
                  setUserPermission((prevValue) =>
                    prevValue?.filter(
                      (permission) => permission !== "READ_FAMILY_MEMBERS"
                    )
                  );
                }
              }}
              checked={userPermission?.includes("READ_FAMILY_MEMBERS")}
              type="switch"
              label="Family Member List"
            />
          </Col>
        </Row>
      </div>
      <hr />
      <div>
        <p className="fw-bold text-primary">Member Permission</p>
        <Row>
          <Col sm={3}>
            <Form.Check
              onChange={(e) => {
                if (e.target?.checked) {
                  setUserPermission((prevValue) => [
                    ...prevValue,
                    "READ_MEMBER",
                  ]);
                } else {
                  setUserPermission((prevValue) =>
                    prevValue?.filter(
                      (permission) => permission !== "READ_MEMBER"
                    )
                  );
                }
              }}
              checked={userPermission?.includes("READ_MEMBER")}
              type="switch"
              label="Read Member Details"
            />
          </Col>

          <Col sm={3}>
            <Form.Check
              onChange={(e) => {
                if (e.target?.checked) {
                  setUserPermission((prevValue) => [
                    ...prevValue,
                    "WRITE_MEMBER",
                  ]);
                } else {
                  setUserPermission((prevValue) =>
                    prevValue?.filter(
                      (permission) => permission !== "WRITE_MEMBER"
                    )
                  );
                }
              }}
              checked={userPermission?.includes("WRITE_MEMBER")}
              type="switch"
              label="Write Member Details"
            />
          </Col>

          <Col sm={3}>
            <Form.Check
              onChange={(e) => {
                if (e.target?.checked) {
                  setUserPermission((prevValue) => [
                    ...prevValue,
                    "UPDATE_MEMBER",
                  ]);
                } else {
                  setUserPermission((prevValue) =>
                    prevValue?.filter(
                      (permission) => permission !== "UPDATE_MEMBER"
                    )
                  );
                }
              }}
              checked={userPermission?.includes("UPDATE_MEMBER")}
              type="switch"
              label="Update Member Details"
            />
          </Col>

          <Col sm={3}>
            <Form.Check
              onChange={(e) => {
                if (e.target?.checked) {
                  setUserPermission((prevValue) => [
                    ...prevValue,
                    "UPDATE_MEMBER_FAMILY",
                  ]);
                } else {
                  setUserPermission((prevValue) =>
                    prevValue?.filter(
                      (permission) => permission !== "UPDATE_MEMBER_FAMILY"
                    )
                  );
                }
              }}
              checked={userPermission?.includes("UPDATE_MEMBER_FAMILY")}
              type="switch"
              label="Import Member"
            />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export const FinancialUserPemission = ({
  userPermission,
  setUserPermission,
}) => {
  return (
    <div>
      {/* Contribution Permission  */}

      <div>
        <p className="fw-bold text-primary">Contribution Permission</p>
        <Row>
          <Col sm={4}>
            <Form.Check
              onChange={(e) => {
                if (e.target?.checked) {
                  setUserPermission((prevValue) => [
                    ...prevValue,
                    "READ_DONATION",
                  ]);
                } else {
                  setUserPermission((prevValue) =>
                    prevValue?.filter(
                      (permission) => permission !== "READ_DONATION"
                    )
                  );
                }
              }}
              checked={userPermission?.includes("READ_DONATION")}
              type="switch"
              label="Read Contribution"
            />
          </Col>

          <Col sm={4}>
            <Form.Check
              onChange={(e) => {
                if (e.target?.checked) {
                  setUserPermission((prevValue) => [
                    ...prevValue,
                    "WRITE_DONATION",
                  ]);
                } else {
                  setUserPermission((prevValue) =>
                    prevValue?.filter(
                      (permission) => permission !== "WRITE_DONATION"
                    )
                  );
                }
              }}
              type="switch"
              checked={userPermission?.includes("WRITE_DONATION")}
              label="Write Contribution"
            />
          </Col>
        </Row>
      </div>
      <hr />

      {/* Expense Permission  */}

      <div>
        <p className="fw-bold text-primary">Expenses Permission</p>
        <Row>
          <Col sm={4}>
            <Form.Check
              onChange={(e) => {
                if (e.target?.checked) {
                  setUserPermission((prevValue) => [
                    ...prevValue,
                    "READ_EXPENSE",
                  ]);
                } else {
                  setUserPermission((prevValue) =>
                    prevValue?.filter(
                      (permission) => permission !== "READ_EXPENSE"
                    )
                  );
                }
              }}
              type="switch"
              checked={userPermission?.includes("READ_EXPENSE")}
              label="Read Expenses"
            />
          </Col>

          <Col sm={4}>
            <Form.Check
              onChange={(e) => {
                if (e.target?.checked) {
                  setUserPermission((prevValue) => [
                    ...prevValue,
                    "WRITE_EXPENSE",
                  ]);
                } else {
                  setUserPermission((prevValue) =>
                    prevValue?.filter(
                      (permission) => permission !== "WRITE_EXPENSE"
                    )
                  );
                }
              }}
              type="switch"
              checked={userPermission?.includes("WRITE_EXPENSE")}
              label="Write Expenses"
            />
          </Col>
        </Row>
      </div>
      <hr />

      {/* Diocese Donation Permission */}

      <div>
        <p className="fw-bold text-primary">Diocese Donation Permission</p>
        <Row>
          <Col sm={4}>
            <Form.Check
              onChange={(e) => {
                if (e.target?.checked) {
                  setUserPermission((prevValue) => [
                    ...prevValue,
                    "READ_COLLECTION",
                  ]);
                } else {
                  setUserPermission((prevValue) =>
                    prevValue?.filter(
                      (permission) => permission !== "READ_COLLECTION"
                    )
                  );
                }
              }}
              type="switch"
              checked={userPermission?.includes("READ_COLLECTION")}
              label="Read Diocese Donation"
            />
          </Col>

          <Col sm={4}>
            <Form.Check
              onChange={(e) => {
                if (e.target?.checked) {
                  setUserPermission((prevValue) => [
                    ...prevValue,
                    "WRITE_COLLECTION",
                  ]);
                } else {
                  setUserPermission((prevValue) =>
                    prevValue?.filter(
                      (permission) => permission !== "WRITE_COLLECTION"
                    )
                  );
                }
              }}
              type="switch"
              checked={userPermission?.includes("WRITE_COLLECTION")}
              label="Write  Diocese Donation"
            />
          </Col>
        </Row>
      </div>
      <hr />
    </div>
  );
};

export const SacramentUserPemission = ({
  userPermission,
  setUserPermission,
}) => {
  return (
    <div>
      {/* Baptism Permission  */}

      <div>
        <p className="fw-bold text-primary">Baptism Permission</p>
        <Row>
          <Col sm={3}>
            <Form.Check
              onChange={(e) => {
                if (e.target?.checked) {
                  setUserPermission((prevValue) => [
                    ...prevValue,
                    "READ_BAPTISM",
                  ]);
                } else {
                  setUserPermission((prevValue) =>
                    prevValue?.filter(
                      (permission) => permission !== "READ_BAPTISM"
                    )
                  );
                }
              }}
              type="switch"
              checked={userPermission?.includes("READ_BAPTISM")}
              label="Read Baptism"
            />
          </Col>

          <Col sm={3}>
            <Form.Check
              onChange={(e) => {
                if (e.target?.checked) {
                  setUserPermission((prevValue) => [
                    ...prevValue,
                    "WRITE_BAPTISM",
                  ]);
                } else {
                  setUserPermission((prevValue) =>
                    prevValue?.filter(
                      (permission) => permission !== "WRITE_BAPTISM"
                    )
                  );
                }
              }}
              type="switch"
              checked={userPermission?.includes("WRITE_BAPTISM")}
              label="Write Baptism"
            />
          </Col>

          <Col sm={3}>
            <Form.Check
              onChange={(e) => {
                if (e.target?.checked) {
                  setUserPermission((prevValue) => [
                    ...prevValue,
                    "UPDATE_BAPTISM",
                  ]);
                } else {
                  setUserPermission((prevValue) =>
                    prevValue?.filter(
                      (permission) => permission !== "UPDATE_BAPTISM"
                    )
                  );
                }
              }}
              type="switch"
              checked={userPermission?.includes("UPDATE_BAPTISM")}
              label="Update Baptism"
            />
          </Col>

          <Col sm={3}>
            <Form.Check
              onChange={(e) => {
                if (e.target?.checked) {
                  setUserPermission((prevValue) => [
                    ...prevValue,
                    "DELETE_BAPTISM",
                  ]);
                } else {
                  setUserPermission((prevValue) =>
                    prevValue?.filter(
                      (permission) => permission !== "DELETE_BAPTISM"
                    )
                  );
                }
              }}
              type="switch"
              checked={userPermission?.includes("DELETE_BAPTISM")}
              label="Delete Baptism"
            />
          </Col>
        </Row>
      </div>
      <hr />

      {/* Communion Permission  */}

      <div>
        <p className="fw-bold text-primary">Holy Communion Permission</p>
        <Row>
          <Col sm={3}>
            <Form.Check
              onChange={(e) => {
                if (e.target?.checked) {
                  setUserPermission((prevValue) => [
                    ...prevValue,
                    "READ_COMMUNION",
                  ]);
                } else {
                  setUserPermission((prevValue) =>
                    prevValue?.filter(
                      (permission) => permission !== "READ_COMMUNION"
                    )
                  );
                }
              }}
              type="switch"
              checked={userPermission?.includes("READ_COMMUNION")}
              label="Read Communion"
            />
          </Col>

          <Col sm={3}>
            <Form.Check
              onChange={(e) => {
                if (e.target?.checked) {
                  setUserPermission((prevValue) => [
                    ...prevValue,
                    "WRITE_COMMUNION",
                  ]);
                } else {
                  setUserPermission((prevValue) =>
                    prevValue?.filter(
                      (permission) => permission !== "WRITE_COMMUNION"
                    )
                  );
                }
              }}
              type="switch"
              checked={userPermission?.includes("WRITE_COMMUNION")}
              label="Write Communion"
            />
          </Col>

          <Col sm={3}>
            <Form.Check
              onChange={(e) => {
                if (e.target?.checked) {
                  setUserPermission((prevValue) => [
                    ...prevValue,
                    "UPDATE_COMMUNION",
                  ]);
                } else {
                  setUserPermission((prevValue) =>
                    prevValue?.filter(
                      (permission) => permission !== "UPDATE_COMMUNION"
                    )
                  );
                }
              }}
              type="switch"
              checked={userPermission?.includes("UPDATE_COMMUNION")}
              label="Update Communion"
            />
          </Col>

          <Col sm={3}>
            <Form.Check
              onChange={(e) => {
                if (e.target?.checked) {
                  setUserPermission((prevValue) => [
                    ...prevValue,
                    "DELETE_COMMUNION",
                  ]);
                } else {
                  setUserPermission((prevValue) =>
                    prevValue?.filter(
                      (permission) => permission !== "DELETE_COMMUNION"
                    )
                  );
                }
              }}
              type="switch"
              checked={userPermission?.includes("DELETE_COMMUNION")}
              label="Delete Communion"
            />
          </Col>
        </Row>
      </div>
      <hr />

      {/* Confirmation Permission  */}

      <div>
        <p className="fw-bold text-primary">Confirmation Permission</p>
        <Row>
          <Col sm={3}>
            <Form.Check
              onChange={(e) => {
                if (e.target?.checked) {
                  setUserPermission((prevValue) => [
                    ...prevValue,
                    "READ_CONFIRMATION",
                  ]);
                } else {
                  setUserPermission((prevValue) =>
                    prevValue?.filter(
                      (permission) => permission !== "READ_CONFIRMATION"
                    )
                  );
                }
              }}
              type="switch"
              checked={userPermission?.includes("READ_CONFIRMATION")}
              label="Read Confirmation"
            />
          </Col>

          <Col sm={3}>
            <Form.Check
              onChange={(e) => {
                if (e.target?.checked) {
                  setUserPermission((prevValue) => [
                    ...prevValue,
                    "WRITE_CONFIRMATION",
                  ]);
                } else {
                  setUserPermission((prevValue) =>
                    prevValue?.filter(
                      (permission) => permission !== "WRITE_CONFIRMATION"
                    )
                  );
                }
              }}
              type="switch"
              checked={userPermission?.includes("WRITE_CONFIRMATION")}
              label="Write Confirmation"
            />
          </Col>

          <Col sm={3}>
            <Form.Check
              onChange={(e) => {
                if (e.target?.checked) {
                  setUserPermission((prevValue) => [
                    ...prevValue,
                    "UPDATE_CONFIRMATION",
                  ]);
                } else {
                  setUserPermission((prevValue) =>
                    prevValue?.filter(
                      (permission) => permission !== "UPDATE_CONFIRMATION"
                    )
                  );
                }
              }}
              type="switch"
              checked={userPermission?.includes("UPDATE_CONFIRMATION")}
              label="Update Confirmation"
            />
          </Col>

          <Col sm={3}>
            <Form.Check
              onChange={(e) => {
                if (e.target?.checked) {
                  setUserPermission((prevValue) => [
                    ...prevValue,
                    "DELETE_CONFIRMATION",
                  ]);
                } else {
                  setUserPermission((prevValue) =>
                    prevValue?.filter(
                      (permission) => permission !== "DELETE_CONFIRMATION"
                    )
                  );
                }
              }}
              type="switch"
              checked={userPermission?.includes("DELETE_CONFIRMATION")}
              type="switch"
              label="Delete Confirmation"
            />
          </Col>
        </Row>
      </div>
      <hr />

      {/* Marriage Permission  */}

      <div>
        <p className="fw-bold text-primary">Marriage Permission</p>
        <Row>
          <Col sm={3}>
            <Form.Check
              onChange={(e) => {
                if (e.target?.checked) {
                  setUserPermission((prevValue) => [
                    ...prevValue,
                    "READ_MARRIAGE",
                  ]);
                } else {
                  setUserPermission((prevValue) =>
                    prevValue?.filter(
                      (permission) => permission !== "READ_MARRIAGE"
                    )
                  );
                }
              }}
              type="switch"
              checked={userPermission?.includes("READ_MARRIAGE")}
              label="Read Marriage"
            />
          </Col>

          <Col sm={3}>
            <Form.Check
              onChange={(e) => {
                if (e.target?.checked) {
                  setUserPermission((prevValue) => [
                    ...prevValue,
                    "WRITE_MARRIAGE",
                  ]);
                } else {
                  setUserPermission((prevValue) =>
                    prevValue?.filter(
                      (permission) => permission !== "WRITE_MARRIAGE"
                    )
                  );
                }
              }}
              type="switch"
              checked={userPermission?.includes("WRITE_MARRIAGE")}
              label="Write Marriage"
            />
          </Col>

          <Col sm={3}>
            <Form.Check
              onChange={(e) => {
                if (e.target?.checked) {
                  setUserPermission((prevValue) => [
                    ...prevValue,
                    "UPDATE_MARRIAGE",
                  ]);
                } else {
                  setUserPermission((prevValue) =>
                    prevValue?.filter(
                      (permission) => permission !== "UPDATE_MARRIAGE"
                    )
                  );
                }
              }}
              type="switch"
              checked={userPermission?.includes("UPDATE_MARRIAGE")}
              label="Update Marriage"
            />
          </Col>

          <Col sm={3}>
            <Form.Check
              onChange={(e) => {
                if (e.target?.checked) {
                  setUserPermission((prevValue) => [
                    ...prevValue,
                    "DELETE_MARRIAGE",
                  ]);
                } else {
                  setUserPermission((prevValue) =>
                    prevValue?.filter(
                      (permission) => permission !== "DELETE_MARRIAGE"
                    )
                  );
                }
              }}
              type="switch"
              checked={userPermission?.includes("DELETE_MARRIAGE")}
              label="Delete Marriage"
            />
          </Col>
        </Row>
      </div>
      <hr />
    </div>
  );
};
