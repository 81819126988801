import React, { useEffect, useState } from "react";
import {
  Accordion,
  Badge,
  Button,
  Col,
  Nav,
  Row,
  Table,
} from "react-bootstrap";

import img29 from "../../assets/img/img29.jpg";
import { Link } from "react-router-dom";
import { withAPIRequest } from "../../HOC/withAPIRequest";
import { API, AXIOS_METHOD_TYPES } from "../../constants/api";
import { LoadingAction } from "../../store/slices/LoadingSlice";
import { useDispatch } from "react-redux";
import AddParishUsersModal from "../../modals/parishes/AddParishUsersModal";
import Swal from "sweetalert2";
import AddNonPriestUserModal from "../../modals/parishes/AddNonPriestUserModal";
import {
  DashboardUserPermission,
  FamiliesUserPermission,
  FinancialUserPemission,
  SacramentUserPemission,
} from "./UserPemission";
import { useFormik } from "formik";

const ParishUsers = ({ selectedTab, commonAPIRequest, churchId }) => {
  const dispatch = useDispatch();
  const [users, setUsers] = useState([]);
  const [addUserModal, setAddUserModal] = useState(false);
  const [addParishUser, setAddParishUser] = useState(false);
  const [currentNav, setCurrentNav] = useState(1);
  const [currentUserId, setCurrentUserId] = useState();
  const [userPermission, setUserPermission] = useState([]);

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      phoneNum: "",
      userEmail: "",
      designation: "",
    },
    onSubmit: (values) => {
      console.log("values");
    },
  });

  const callGetAllParishUsers = () => {
    let serviceParams = {
      api: `${API.GET_ALL_USERS_RESOURCE}?pgNum=1&pgSize=100&churchId=${churchId}`,
      method: AXIOS_METHOD_TYPES.GET,
      data: {},
    };
    dispatch(LoadingAction.startTranslucentLoader());
    commonAPIRequest(serviceParams, async (result) => {
      if (result) {
        dispatch(LoadingAction.endTranslucentLoader());
        try {
          console.log("users", result.payload);
          setUsers(result?.payload?.userList);
          // setParishes(result?.payload?.churchList);
        } catch (error) {
          console.log("error", error);
        }
      } else {
        dispatch(LoadingAction.endTranslucentLoader());
      }
    });
  };

  const deleteParishUser = (id) => {
    let serviceParams = {
      api: `${API.DELETE_USER_INFO}?userId=${id}`,
      method: AXIOS_METHOD_TYPES.DELETE,
      data: {},
    };
    commonAPIRequest(serviceParams, async (result) => {
      if (result) {
        try {
          Swal.fire({
            title: "Deleted!",
            text: "User has been deleted.",
            icon: "success",
          });
          callGetAllParishUsers();
        } catch (error) {
          console.log("error", error);
        }
      }
    });
  };

  const callGetUserDetailApi = (id) => {
    let serviceParams = {
      api: `${API.GET_USER_DETAIL}?userId=${id}`,
      method: AXIOS_METHOD_TYPES.GET,
      data: {},
    };
    dispatch(LoadingAction.startTranslucentLoader());
    commonAPIRequest(serviceParams, async (result) => {
      if (result) {
        dispatch(LoadingAction.endTranslucentLoader());
        try {
          // formik;
          formik.setFieldValue("firstName", result?.payload?.firstName);
          formik.setFieldValue("lastName", result?.payload?.lastName);
          formik.setFieldValue("phoneNum", result?.payload?.phoneNum);
          formik.setFieldValue("userEmail", result?.payload?.userEmail);
          formik.setFieldValue("designation", result?.payload?.designation);

          setUserPermission(result?.payload?.permissions);
          // setParishes(result?.payload?.churchList);
        } catch (error) {
          console.log("error", error);
        }
      } else {
        dispatch(LoadingAction.endTranslucentLoader());
      }
    });
  };

  const callEditUserDetailApi = (id) => {
    let serviceParams = {
      api: `${API.GET_USER_DETAIL}?userId=${id}`,
      method: AXIOS_METHOD_TYPES.PUT,
      data: {
        firstName: formik.values?.firstName,
        lastName: formik.values?.lastName,
        phoneNum: formik.values?.phoneNum,
        userEmail: formik.values?.userEmail,
        designation: formik.values?.designation,
        userPermissions: userPermission,
      },
    };
    dispatch(LoadingAction.startTranslucentLoader());
    commonAPIRequest(serviceParams, async (result) => {
      if (result) {
        dispatch(LoadingAction.endTranslucentLoader());
        try {
          // setUserPermission(result?.payload?.userPermissions);
          // setParishes(result?.payload?.churchList);
          Swal.fire("Updated", "Permission Updated Successfully", "success");
        } catch (error) {
          console.log("error", error);
        }
      } else {
        dispatch(LoadingAction.endTranslucentLoader());
      }
    });
  };

  useEffect(() => {
    if (selectedTab === 2) {
      callGetAllParishUsers();
    }
  }, [selectedTab]);

  useEffect(() => {
    if (currentUserId) {
      callGetUserDetailApi(currentUserId);
    }
  }, [currentUserId]);

  console.log("dsadsdsd", userPermission);

  return (
    <Row>
      <div className="d-flex justify-content-end mb-3">
        <Button
          className="mx-2"
          onClick={() => {
            setAddUserModal(true);
          }}
        >
          Add Priest
        </Button>
        <Button onClick={() => setAddParishUser(true)}>Add User</Button>
      </div>
      <Col xs="12" sm>
        <Accordion className="accordion-light">
          {users.map((user, index) => (
            <Accordion.Item
              onClick={() => {
                setCurrentUserId(user?.userId);
              }}
              className="mb-4 shadow"
              key={index}
              eventKey={index}
            >
              <Accordion.Header>
                <div
                  style={{ width: "100%" }}
                  className="d-flex align-items-center justify-content-between"
                >
                  <div className="d-flex align-items-center">
                    <span className="me-3 fw-bold text-primary">
                      {index + 1}
                    </span>
                    <div className="media-music">
                      <Link to="" className="media-img">
                        <img src={img29} alt="" />
                      </Link>
                      <div className="media-body">
                        <div className="d-flex align-items-center">
                          <h6 className="fw-bold text-primary mb-0">
                            {user.firstName} {user?.lastName}
                          </h6>
                          <Badge
                            className="text-white ms-2 fw-bold"
                            pill
                            bg="primary"
                          >
                            ADMIN
                          </Badge>
                        </div>
                        <span>{user.phoneNum}</span>
                        <span>{user.userEmail}</span>
                      </div>
                    </div>
                  </div>
                  <div>
                    <Button
                      onClick={() => {}}
                      variant="warning"
                      className="me-2"
                    >
                      <i className="ri-edit-line"></i>
                    </Button>
                    <Button
                      onClick={() => {
                        Swal.fire({
                          title: "Are you sure?",
                          text: "You won't be able to revert this!",
                          icon: "warning",
                          showCancelButton: true,
                          confirmButtonColor: "#3085d6",
                          cancelButtonColor: "#7e7e7e",
                          confirmButtonText: "Yes, delete it!",
                        }).then((result) => {
                          if (result.isConfirmed) {
                            deleteParishUser(user?.userId);
                          }
                        });
                      }}
                      variant="danger"
                      className="me-2"
                    >
                      <i className="ri-delete-bin-6-line"></i>
                    </Button>
                  </div>
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <h5 className="mb-2">
                  <strong>Manage Permissions.</strong>
                </h5>
                <hr className="mt-0" />

                {/* nav bar */}

                <Nav variant="pills">
                  <Nav.Item onClick={() => setCurrentNav(1)}>
                    <Nav.Link active={currentNav === 1}>
                      Dashboard & Feeds
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item onClick={() => setCurrentNav(2)}>
                    <Nav.Link active={currentNav === 2}>Families</Nav.Link>
                  </Nav.Item>
                  <Nav.Item onClick={() => setCurrentNav(3)}>
                    <Nav.Link active={currentNav === 3}>Financials</Nav.Link>
                  </Nav.Item>
                  <Nav.Item onClick={() => setCurrentNav(4)}>
                    <Nav.Link active={currentNav === 4}>Sacraments</Nav.Link>
                  </Nav.Item>
                </Nav>
                <hr className="mt-0" />
                {currentNav === 1 && (
                  <DashboardUserPermission
                    userPermission={userPermission}
                    setUserPermission={setUserPermission}
                  />
                )}

                {currentNav === 2 && (
                  <FamiliesUserPermission
                    userPermission={userPermission}
                    setUserPermission={setUserPermission}
                  />
                )}

                {currentNav === 3 && (
                  <FinancialUserPemission
                    userPermission={userPermission}
                    setUserPermission={setUserPermission}
                  />
                )}
                {currentNav === 4 && (
                  <SacramentUserPemission
                    userPermission={userPermission}
                    setUserPermission={setUserPermission}
                  />
                )}
                <hr />

                <div className="d-flex align-items-end justify-content-end">
                  <Button
                    onClick={() => {
                      callEditUserDetailApi(user?.userId);
                    }}
                  >
                    Apply
                  </Button>
                </div>
              </Accordion.Body>
            </Accordion.Item>
          ))}
        </Accordion>
      </Col>

      <AddParishUsersModal
        show={addUserModal}
        setShow={setAddUserModal}
        churchId={churchId}
        callGetAllParishUsers={callGetAllParishUsers}
      />
      <AddNonPriestUserModal
        show={addParishUser}
        setShow={setAddParishUser}
        callGetAllParishUsers={callGetAllParishUsers}
        churchId={churchId}
      />
    </Row>
  );
};

export default withAPIRequest(ParishUsers);
